<template>
  <c-box
    w="100%"
    mx="auto"
    :min-h="['calc(100vh - 62px)', 'auto']"
    :bg="['#FFF', '#FFF']"
    border="1px solid #f2f2f2"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :p="['1rem', '1.5rem']"
    :min-height="['unset', '74vh']"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        px="90px"
        py="20px"
        :paths="breadcrumb"
      />
    </Portal>
    <c-flex
      flex-direction="column"
      bg="white"
      :p="['10px', '0']"
      :mx="[null, 'auto']"
      :gap="['10px', '16px']"
      border-radius="12px"
      max-w="540px"
      w="100%"
    >
      <c-text
        font-family="Roboto"
        :font-size="['28px']"
        :font-weight="['700']"
        :display="['none', 'block']"
        pb="24px"
      >
        Ubah Password
      </c-text>

      <c-form-control
        :is-required="isGoogle === 1 ? false : true"
        :is-invalid="$v.oldPassword.$invalid"
        max-w="540px"
        :display="isGoogle === 1 ? 'none' : 'block'"
      >
        <c-form-label
          px="20px"
          font-family="Roboto"
          :font-size="['14px', '16px']"
          :font-weight="['400']"
          :color="$v.oldPassword.$invalid ? 'black' : '#008C81'"
        >
          Kata Sandi Lama
        </c-form-label>
        <c-input-group>
          <c-input-left-element>
            <c-image
              variant="ghost"
              top="50%"
              :transform="['translateY(10%)', 'translateY(30%)']"
              :src="
                $v.oldPassword.$invalid
                  ? require('@/assets/icons/icon-lock-grey.svg')
                  : require('@/assets/icons/icon-lock-green.svg')
              "
              alt="toggle password visibility"
              :h="['20px', '30px']"
              :w="['20px', '30px']"
              :ml="['20px']"
            />
          </c-input-left-element>
          <c-input
            v-model="oldPassword"
            v-chakra="{
              ':focus': {
                outline: 'none',
                boxShadow: 'none !important',
                background: 'none !important',
              },
            }"
            :type="shownPassword.includes('oldPassword') ? `text` : `password`"
            placeholder="Masukkan Password Lama"
            :h="['48px', '62px']"
            tabindex="1"
            font-family="Roboto"
            :font-size="['14px', '18px']"
            :font-weight="['500']"
            border-radius="8px"
            :color="$v.oldPassword.$invalid ? 'black' : '#008C81'"
            :border="$v.oldPassword.$invalid ? '1px solid #888888' : '1px solid #008C81'"
            w="100%"
          />
          <c-input-right-element>
            <c-button
              v-chakra="{
                ':focus': {
                  outline: 'none',
                  boxShadow: 'none !important',
                  background: 'none !important',
                },
              }"
              variant="ghost"
              p="0"
              m="0"
              top="50%"
              right="10px"
              :transform="['translateY(-40%)', 'translateY(-20%)']"
              @click="togglePasswordVisibility('oldPassword')"
            >
              <c-image
                :src="
                  $v.oldPassword.$invalid
                    ? require('@/assets/icons/icon-show-password-grey.svg')
                    : require('@/assets/icons/icon-show-password-green.svg')
                "
                alt="toggle password visibility"
                :h="['20px', '30px']"
                :w="['20px', '30px']"
              />
            </c-button>
          </c-input-right-element>
        </c-input-group>
      </c-form-control>
      <c-form-control
        is-required
        :is-invalid="$v.newPassword.$invalid"
        max-w="540px"
      >
        <c-form-label
          px="20px"
          font-family="Roboto"
          :font-size="['14px', '16px']"
          :font-weight="['400']"
          :color="$v.newPassword.$invalid ? 'black' : '#008C81'"
        >
          Kata Sandi Baru
        </c-form-label>
        <c-input-group>
          <c-input-left-element>
            <c-image
              variant="ghost"
              top="50%"
              :transform="['translateY(10%)', 'translateY(30%)']"
              :src="
                $v.newPassword.$invalid
                  ? require('@/assets/icons/icon-lock-grey.svg')
                  : require('@/assets/icons/icon-lock-green.svg')
              "
              alt="toggle password visibility"
              :h="['20px', '30px']"
              :w="['20px', '30px']"
              :ml="['20px']"
            />
          </c-input-left-element>
          <c-input
            v-model="newPassword"
            v-chakra="{
              ':focus': {
                outline: 'none',
                boxShadow: 'none !important',
                background: 'none !important',
              },
            }"
            :type="shownPassword.includes('newPassword') ? `text` : `password`"
            placeholder="Masukkan Password Baru"
            :h="['48px', '62px']"
            max-w="540px"
            tabindex="1"
            font-family="Roboto"
            :font-size="['14px', '18px']"
            :font-weight="['500']"
            border-radius="8px"
            :color="$v.newPassword.$invalid ? 'black' : '#008C81'"
            :border="$v.newPassword.$invalid ? '1px solid #888888' : '1px solid #008C81'"
          />
          <c-input-right-element>
            <c-button
              v-chakra="{
                ':focus': {
                  outline: 'none',
                  boxShadow: 'none !important',
                  background: 'none !important',
                },
              }"
              variant="ghost"
              p="0"
              m="0"
              top="50%"
              right="10px"
              :transform="['translateY(-40%)', 'translateY(-20%)']"
              @click="togglePasswordVisibility('newPassword')"
            >
              <c-image
                :src="
                  $v.newPassword.$invalid
                    ? require('@/assets/icons/icon-show-password-grey.svg')
                    : require('@/assets/icons/icon-show-password-green.svg')
                "
                alt="toggle password visibility"
                :h="['20px', '30px']"
                :w="['20px', '30px']"
              />
            </c-button>
          </c-input-right-element>
        </c-input-group>
        <c-form-helper-text
          v-if="!$v.newPassword.minLength"
          color="red.500"
        >
          Password minimal 8 karakter
        </c-form-helper-text>
        <c-form-helper-text
          v-if="!$v.newPassword.hasNumber"
          color="red.500"
        >
          Password harus mengandung angka
        </c-form-helper-text>
        <c-form-helper-text
          v-if="!$v.newPassword.hasLowercaseLetter"
          color="red.500"
        >
          Password harus mengandung huruf kecil
        </c-form-helper-text>
        <c-form-helper-text
          v-if="!$v.newPassword.hasUppercaseLetter"
          color="red.500"
        >
          Password harus mengandung huruf kapital
        </c-form-helper-text>
        <c-form-helper-text
          v-if="!$v.newPassword.hasSpecialCharacter"
          color="red.500"
        >
          Password harus mengandung karakter spesial @$!%()*?&
        </c-form-helper-text>
        <c-form-helper-text
          v-if="!$v.newPassword.sameAs"
          color="red.500"
        >
          Kata sandi baru tidak boleh sama dengan kata sandi sebelumnya
        </c-form-helper-text>
      </c-form-control>

      <c-form-control
        is-required
        :is-invalid="$v.newPasswordConfirmation.$invalid"
        max-w="540px"
      >
        <c-form-label
          px="20px"
          font-family="Roboto"
          :font-size="['14px', '16px']"
          :font-weight="['400']"
          :color="$v.newPasswordConfirmation.$invalid ? 'black' : '#008C81'"
        >
          Ulangi Kata Sandi Baru
        </c-form-label>
        <c-input-group>
          <c-input-left-element>
            <c-image
              variant="ghost"
              top="50%"
              :transform="['translateY(10%)', 'translateY(30%)']"
              :src="
                $v.newPasswordConfirmation.$invalid
                  ? require('@/assets/icons/icon-lock-grey.svg')
                  : require('@/assets/icons/icon-lock-green.svg')
              "
              alt="toggle password visibility"
              :h="['20px', '30px']"
              :w="['20px', '30px']"
              :ml="['20px']"
            />
          </c-input-left-element>
          <c-input
            v-model="newPasswordConfirmation"
            v-chakra="{
              ':focus': {
                outline: 'none',
                boxShadow: 'none !important',
                background: 'none !important',
              },
            }"
            :type="shownPassword.includes('newPasswordConfirmation') ? `text` : `password`"
            placeholder="Ulangi Password Baru"
            :h="['48px', '62px']"
            max-w="540px"
            tabindex="1"
            font-family="Roboto"
            :font-size="['14px', '18px']"
            :font-weight="['500']"
            border-radius="8px"
            :color="$v.newPasswordConfirmation.$invalid ? 'black' : '#008C81'"
            :border="$v.newPasswordConfirmation.$invalid ? '1px solid #888888' : '1px solid #008C81'"
          />
          <c-input-right-element>
            <c-button
              v-chakra="{
                ':focus': {
                  outline: 'none',
                  boxShadow: 'none !important',
                  background: 'none !important',
                },
              }"
              variant="ghost"
              p="0"
              m="0"
              top="50%"
              right="10px"
              :transform="['translateY(-40%)', 'translateY(-20%)']"
              @click="togglePasswordVisibility('newPasswordConfirmation')"
            >
              <c-image
                :src="
                  $v.newPasswordConfirmation.$invalid
                    ? require('@/assets/icons/icon-show-password-grey.svg')
                    : require('@/assets/icons/icon-show-password-green.svg')
                "
                alt="toggle password visibility"
                :h="['20px', '30px']"
                :w="['20px', '30px']"
              />
            </c-button>
          </c-input-right-element>
        </c-input-group>
        <!--Show error here-->
        <!-- <c-form-helper-text
          v-if="!$v.newPasswordConfirmation.sameAs"
          color="red.500"
        >
          Password baru tidak sama
        </c-form-helper-text> -->
      </c-form-control>
    </c-flex>
    <c-flex
      flex-direction="row"
      gap="16px"
      :display="['none', 'flex']"
      max-w="540px"
      w="100%"
      mx="auto"
      mt="40px"
    >
      <c-button
        v-chakra="{
          ':hover': {
            bg: '#C4C4C4',
          },
        }"
        w="100%"
        h="70px"
        bg="#C4C4C4"
        color="white"
        border-radius="58px"
        p="10px 50px"
        font-family="Roboto"
        :font-size="['18px']"
        :font-weight="['500']"
        @click="$router.push('/')"
      >
        Batal
      </c-button>
      <c-button
        v-chakra="{
          ':hover': {
            bg: '#008C81',
          },
          ':disabled': {
            opacity: '1 !important',
          },
        }"
        :disabled="$v.$invalid"
        w="100%"
        h="70px"
        bg="#008C81"
        color="white"
        border-radius="58px"
        font-family="Roboto"
        :font-size="['18px']"
        :font-weight="['500']"
        p="10px 50px"
        @click="openModalConfirm"
      >
        Simpan
      </c-button>
    </c-flex>
    <c-flex
      px="20px"
      mx="-20px"
      bottom="40px"
      w="100%"
      pos="fixed"
      :display="['block', 'none']"
    >
      <!-- <c-button
        mt="40px"
        w="100%"
        h="62px"
        font-weight="bold"
        font-size="16px"
        rounded="full"
        @click="$router.push('/')"
      >
        Batal
      </c-button> -->
      <c-button
        :disabled="$v.$invalid"
        w="100%"
        h="50px"
        :bg="$v.$invalid ? '#C4C4C4' : '#008C81'"
        color="white"
        border-radius="58px"
        font-family="Roboto"
        :font-size="['14px']"
        :font-weight="['500']"
        p="10px 50px"
        @click="openModalConfirm"
      >
        Simpan
      </c-button>
    </c-flex>
    <ModalConfirmPassword
      v-show="isConfirm"
      :is-open="isConfirm"
      @handle-close="handleCloseConfirmModal"
      @updatePassword="updatePassword"
    />
    <ModalSuccessPassword
      v-show="isSucceeded"
      :is-open="isSucceeded"
      @handle-close="handleCloseSuccessModal"
    />
    <ModalErrorPassword
      v-show="isError"
      :is-open="isError"
      @handle-close="handleCloseErrorModal"
    />
  </c-box>
</template>

<script>
import { required, sameAs, minLength } from 'vuelidate/lib/validators'
import ModalConfirmPassword from '../../components/widgets/modal-confirm-password.vue'
import ModalSuccessPassword from '../../components/widgets/modal-success-password.vue'
import ModalErrorPassword from '../../components/widgets/modal-error-password.vue'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'SettingsProfile',
  components: {
    ModalConfirmPassword,
    ModalSuccessPassword,
    ModalErrorPassword,
    BreadcrumbPath,
  },
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
      shownPassword: [],
      isSucceeded: false,
      isConfirm: false,
      isError: false,
    }
  },
  computed: {
    ...mapState({
      isGoogle: (s) => s.auth.isGoogle,
    }),
    breadcrumb() {
      if (this.isClient) {
        return [
          {
            label: 'Dashboard',
            href: { name: 'client.index' },
          },
          {
            label: 'Profil',
            href: { name: 'client.profile' },
          },
          {
            label: 'Akun',
            href: { name: 'client.profile.account' },
          },
          {
            label: 'Ubah Password',
            isCurrent: true,
          },
        ]
      }

      return [
        {
          label: 'Beranda',
          href: { name: 'nutrisionist.managementClient' },
        },
        {
          label: 'Akun',
          href: { name: 'client.profile.account' },
        },
        {
          label: 'Ubah Password',
          isCurrent: true,
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      signout: 'auth/signout',
      updateIsGoogle: 'auth/updateIsGoogle',
    }),
    updatePassword() {
      this.$v.$touch()
      this.isConfirm = false
      if (this.$v.$invalid) return

      return this.axios
        .put('/v1/users/update-password', {
          currentPassword: this.oldPassword,
          newPassword: this.newPassword,
        })
        .then(() => {
          this.isSucceeded = true
          this.$v.$reset()
          this.oldPassword = ''
          this.newPassword = ''
          this.newPasswordConfirmation = ''
          this.updateIsGoogle()
        })
        .catch((error) => {
          // Handle the error here
          console.error(error)
          // if (error.response.data.message === 'New Password cannot same as existing one') {
          //   this.isError = true
          // } else {
          //   this.isError = true
          // }
          this.isError = true
        })
    },
    openModalConfirm() {
      this.isConfirm = true
    },
    handleCloseConfirmModal() {
      this.isConfirm = false
    },
    handleCloseSuccessModal() {
      this.isSucceeded = false
      if (this.isClient) {
        this.$router.push('/')
      } else {
        this.$router.push('/management-client')
      }
    },
    handleCloseErrorModal() {
      this.isError = false
    },
    togglePasswordVisibility(name) {
      if (this.shownPassword.includes(name)) {
        this.shownPassword = this.shownPassword.filter((it) => it !== name)
      } else {
        this.shownPassword.push(name)
      }
    },
  },
  validations: {
    oldPassword: {
      required: function(value){
        return this.isGoogle === 1 || value
      },
    },
    newPassword: {
      required,
      minLength: minLength(8),
      hasNumber(value) {
        return /\d/.test(value)
      },
      hasLowercaseLetter(value) {
        return /[a-z]/.test(value)
      },
      hasUppercaseLetter(value) {
        return /[A-Z]/.test(value)
      },
      hasSpecialCharacter(value) {
        let reSpecialCharacter = /[@$!%()*?&]/
        // eslint-disable-next-line no-useless-escape
        return reSpecialCharacter.test(value)
      },
      sameAs: function(value) {
        return value !== this.oldPassword
      }, 
    },
    newPasswordConfirmation: { required, sameAs: sameAs('newPassword') },
  },
}
</script>

<style scoped>
::v-deep [data-chakra-component='CRequiredIndicator'] {
  display: none;
}
::v-deep [data-chakra-component='CInput'] {
  padding-inline: 50px;
}
@media only screen and (min-width: 768px) {
  ::v-deep [data-chakra-component='CInput'] {
    padding-inline: 60px;
  }
}
</style>